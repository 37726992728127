import React from 'react';
import '../graphql-fragments/localeFragments.ts';

import IndexPage from '../components/IndexPage';

const CitizenshipUsIndexPage = (): React.ReactElement => {
  return (
    <IndexPage
      country="us"
      pageTheme="citizenship"
      googleTagManagerTrackingId="GTM-N8HFB2G"
    ></IndexPage>
  );
};

export default CitizenshipUsIndexPage;
